import { Project } from './Project';
import fs from 'fs';
const path  = require('path');
import "@babel/polyfill";




import $ from 'jquery';
window.$ = window.jQuery = $;


import 'imagesloaded';
// end of class definition

var lastScrollTop = 0;
// var npak;
// var ratio;
// var mutex = 0;

var projects_names = ['npak', 'pairidaeza', 'lighthouse', 'observatory', 'monolith', 'bunker', 'platform'];
var project_list = [];


//populate project list
projects_names.forEach(function (item, index) {
    project_list.push(new Project(item))
});


/** PAGE LOAD **/


window.onload = function () {

    //home nav clicks
    var homepage = document.getElementById("homepage");

    homepage.addEventListener('click', () => scrollup());



    //add scrolling header effects
    addEventListener("scroll", function () { // or window.addEventListener("scroll"....
        var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
        if (st > lastScrollTop) {
            hide_header(); // downscroll code
        } else {
            show_header(); // upscroll code
        }
        lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    }, false);


};

//variables to be used
let slicktiriji = null;
let timerID = null;
let timerCount =0;

$(document).ready(function () {
    //quickFixSlideHeights();
    //configure projects

    //mobile navigation hamburger
    var burger = $('.hamburger');
    burger.on('click', function () {
        burger.toggleClass("is-active");
    });


    //get galleries

    //pairidaeza
    var data = fs.readFileSync(__dirname +'/pairidaeza-first-image.jpg');
    var pairidaeza_1 = document.getElementById("source-width");
    pairidaeza_1.src = `data:image/jpg;base64,${data.toString('base64')}`;
    console.log("para done");
    console.log("width: " + pairidaeza_1.naturalWidth);
    console.log("height: " + pairidaeza_1.naturalHeight);
    
    
    //npak
    // var data = fs.readFileSync( __dirname +'/npak-first-image.jpg');
    // var npak_1 = document.getElementById("npak-first-image");
    // npak_1.src = `data:image/jpg;base64,${data.toString('base64')}`;
    // npak_1.style.visibility = "visible";
    // pairidaeza_1.style.visibility = "visible";
    

    //project title clicks
    project_list.forEach(addModalClick);



    let sw = $('#source-width').width();
    console.log("source width: " + sw);

    $('.project-title').css('width', sw + "px");

    //configuring projects
    project_list.forEach(function (item, index) {
        

        configureProject(item)
        if(item.name == "npak")
            console.log(item.ratio);
    });


    //lighthouse
    // var data = fs.readFileSync( __dirname +'/lighthouse-first-image.jpg');
    // var lighthouse_1 = document.getElementById("lighthouse-first-image");
    // lighthouse_1.src = `data:image/jpg;base64,${data.toString('base64')}`;

    // //observatory
    // var data = fs.readFileSync( __dirname +'/observatory-first-image.jpg');
    // var observatory_1 = document.getElementById("observatory-first-image");
    // observatory_1.src = `data:image/jpg;base64,${data.toString('base64')}`;

    // //monolith
    // var data = fs.readFileSync( __dirname +'/monolith-first-image.jpg');
    // var monolith_1 = document.getElementById("monolith-first-image");
    // monolith_1.src = `data:image/jpg;base64,${data.toString('base64')}`;

    // //bunker
    // var data = fs.readFileSync( __dirname +'/bunker-first-image.jpg');
    // var bunker_1 = document.getElementById("bunker-first-image");
    // bunker_1.src = `data:image/jpg;base64,${data.toString('base64')}`;

    // //platform
    // var data = fs.readFileSync( __dirname +'/platform-first-image.jpg');
    // var platform_1 = document.getElementById("platform-first-image");
    // platform_1.src = `data:image/jpg;base64,${data.toString('base64')}`;



    

    

    

    //slick carousel stuff

    

    $('.slideshow').on('init', function (event, slick) {
        slicktiriji = slick;
        fixSlideHeights(slick);
        console.log("init???");
        $('img').css('visibility', 'visible');
    }).on('lazyLoaded', function (event, slick, image, imageSource) {
        console.log("lazyLoaded?");
        fixSlideHeights(slick);
        $('img').css('visibility', 'visible');
    });


    $('.slideshow').slick({
        arrows: true,
        dots: false,
        infinite: true,
        swipe: true,
        touchMove: true,
        speed: 600,
        slidesToShow: 1,
        lazyLoad: "progressive",
        prevArrow: "<div class='left-navigation'>  <div class='left-arrow'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path d='m70.756156 6.24865598 3.314563 3.31456304-40.88125 40.87918698 40.88125 40.8796875-3.3148755 3.314563-40.8788745-40.8796875-3.314563-3.314563 3.3142505-3.3140625.0003125.0015625z' class='arrow' transform='translate(100, 100) rotate(180) '></path></svg></div></div>",
        nextArrow: "<div class='right-navigation'><div class='right-arrow'><svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path d='m70.756156 6.24865598 3.314563 3.31456304-40.88125 40.87918698 40.88125 40.8796875-3.3148755 3.314563-40.8788745-40.8796875-3.314563-3.314563 3.3142505-3.3140625.0003125.0015625z' class='arrow' transform='translate(100, 100) rotate(180) '></path></svg></div></div>"
    })
        .on('setPosition', function (event, slick) {
            //console.log('on set position: ' + $('.first-image').length);
            fixSlideHeights(slick);
        });

    // end o fslick carousel stuff




    // npak = $('.first-image');
    // ratio = npak.height() / npak.width();

    $("img")
        .on('load', function () {
            console.log("image loaded correctly");

            fixSlideHeights(slicktiriji);
            $('img').css('visibility', 'visible');
        })

        .on('error', function () { console.log("error loading image"); })
        //.attr("src", "01.1.ba470bad")
        ;
        
    timerID = setInterval(fixSlidesNoParams,1000);
});

/** END OF PAGE LOAD **/


/** PROJECT STUFF **/
function configureProject(project) {
    let query = '#' + project.name + '-first-image';
    
    project.first_image = $(query);

    //console.log(project.first_image.attr('src'));
    project.ratio = project.first_image.height() / project.first_image.width();
}
/** PROJECT STUFF **/

/** CAROUSEL STUFF **/

function fixSlidesNoParams()
{
    if(slicktiriji != null)
    {
        fixSlideHeights(slicktiriji);
        console.log("calling timer");
        timerCount++;

        if(timerCount>30)
        {
            clearInterval(timerID);
        }
    }
    
}

function fixSlideHeights(slick) {
    if (slick.$slides.children('.first-image').length > 0) {


        let first_slide = slick.$slides.children('.first-image');
        //first_slide.css('visibility', 'hidden');

        let slide_proj_title = first_slide.attr('id').split('-')[0];
        // console.log("project title: " + slide_proj_title);

        first_slide = $('#' + slide_proj_title + '-slideshow').find('.first-image')


        let slide_proj = project_list.find(proj => proj.name == slide_proj_title);



        if (slide_proj.mutex == 0) {

            let pairidaeza = $('#source-width');

            first_slide.each(function (index) {
                first_slide.css('width', pairidaeza.width() + 'px');
                first_slide.css('height', (first_slide.width() * slide_proj.ratio) + 'px');
                first_slide.css('margin-top', (slick.$slideTrack.height() / 2 - (first_slide.height() / 2)) + 'px');
            });

            $('.project-title').css('width', pairidaeza + "px");


            //insert div method
            // let div_height = (slick.$slideTrack.height() / 2 - (first_slide.height() / 2));
            // $('<div style="height:' + div_height + 'px;display:block;background-color:yellow;">EMPTY</div>').insertBefore(first_slide);


            if (pairidaeza.width() > 0 && pairidaeza.height() > 0  &&  first_slide.width() == pairidaeza.width() ) {
                slide_proj.mutex = 1;
                // first_slide.css('visibility', 'visible');
                // first_slide.removeClass('first-image');
            }
            else
            {
                console.log("not yet fixed");
            }

            //slick.$slides.css('height', slick.$slideTrack.height() + 'px');
        }
        // else
        //     first_slide.css('visibility', 'visible');


    }
}
/** CAROUSEL STUFF **/



/** HEADER STUFF **/

function scrollup() {
    window.scroll({
        top: 0,
        left: 240,
        behavior: 'smooth'
    });
}

function show_header() {
    var hd = document.getElementById('header_container');
    if (typeof hd != "undefined" && hd != null) {
        //hd.parentNode.parentNode.parentNode.parentNode.style.top = "0px";
        hd.parentNode.style.top = "0px";
        var burger = document.getElementsByClassName('hamburger')[0];

        if (burger.getAttribute("aria-expanded") == "true") {
            console.log("ITS EXPANDED!");
            burger.click();
        }
    }
}

function hide_header() {
    var hd = document.getElementById('header_container');
    var burger = document.getElementsByClassName('hamburger')[0];
    //console.log(hd);
    if (typeof hd != "undefined" && hd != null) {

        hd.parentNode.style.top = "-100px";

        if (burger.getAttribute("aria-expanded") == "true") {
            console.log("ITS EXPANDED!");
            burger.click();
        }
    }
}
/** END HEADER STUFF **/



/** MODAL STUFF **/


function addModalClick(project) {
    let s = '#' + project.project_title;

    let modal_text_query = '#' + project.name + '-text ' + ' h2:first';
    //alert("title: " + modal_text_query);

    let projectQ = $(s);
    //project.addEventListener('click', ()=> show_text(project_text,project_title),false);
    projectQ.click(() => show_text(project.project_text, project.project_title));




    let modal_titleQ = $(modal_text_query);
    modal_titleQ.click(() => hide_text(project.project_text, project.project_title));
}

function show_text(modal_id, title_id) {
    // Get the modal
    var project_title = document.getElementById(title_id);
    var modal = document.getElementById(modal_id);


    modal.style.height = "100%";
    project_title.style.opacity = "0";

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function (event) {
        if (event.target == modal) {
            hide_text(modal_id, title_id);
        }
    }

    //if the user scrolls hide text modal
    document.addEventListener("scroll", function () { hide_text(modal_id, title_id) });

}

function hide_text(modal_id, title_id) {
    var project_title = document.getElementById(title_id);
    var modal = document.getElementById(modal_id);

    modal.style.height = "0%";
    project_title.style.opacity = "1";
    //document.removeEventListener("wheel",function(){hide_text(modal,project_title)});
}

/** END OF MODAL STUFF **/