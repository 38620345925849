class Project
{
    constructor(name)
    {
        this.name = name;
        this.mutex = 0;
        this.project_title = name + "-title";
        this.project_text = name + "-text";
        this.project_slideshow = name + "-slideshow";
        this.ratio =0;
        this.htmlElement = "";
        this.first_image = undefined;
    }
}

export {Project}