


// /* JQuery */
var jquery = require("jquery");
window.$ = window.jQuery = jquery; // notice the definition of global variables here

import $ from 'jquery';
window.$ = window.jQuery = $;



/* BOOTSTRAP */
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css' // Import precompiled Bootstrap css
import '@fortawesome/fontawesome-free/css/all.css'



/* Hamburgers */
import 'hamburgers';
import 'hamburgers/dist/hamburgers.min.css';

// /* SLICK  */
import './slick/slick.css';
import './slick/slick-theme.css'
import './slick/slick.min.js';
// // require('./assets/slick/slick.js');

/* donno if working */
import 'imagesloaded';

//import progressively from 'progressively';
import 'progressively/dist/progressively.min.css';
import 'progressively/dist/progressively.min.js';



import './js/garen';

/* OUR STYLE */
import './styles/stylesheet.css';



